import React from 'react';
import PracticeArea from './PracticeArea';
import Header from '../Header';
import Footer from '../Footer';

export default function Practice() {
  return (
    <>
      <Header />
      <div className="bg-whiteDefault">
        <div className="bg-no-repeat bg-cover w-[100%] h-[380px] md:h-[400px] text-left bg-practiceslide">
          <p className=" w-full p-16 text-center font-RaleRegular text-light text-3xl md:py-36 md:pb-20">
            Services
          </p>
        </div>
        <PracticeArea />
      </div>
      <Footer />
    </>
  );
}
