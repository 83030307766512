import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import MainInfo from '../MainInfo';

export default function Slider() {
  return (
    <Carousel
      axis="horizontal"
      autoPlay={true}
      infiniteLoop={true}
      interval="4000"
      animationHandler={'fade'}
      showArrows={false}
      showThumbs={false}
      showStatus={false}>
      <div className="bg-no-repeat bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-mainslide">
        <MainInfo
          headText={'Legal solutions for you'}
          NormalText={
            'Whatever your rights you want we can bring it for you available at the easiest.'
          }
          url="/"
        />
      </div>
      <div className="bg-no-repeat bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-practiceslide">
        <MainInfo
          headText={'Legal Practice'}
          NormalText={'All the practices only to bring in the peace to all.'}
          url="/services"
        />
      </div>
      <div className="bg-no-repeat bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-clientslide">
        <MainInfo
          headText={'People relying on us for the trust'}
          NormalText={'Bringing the adjudicator to the people who trust us and believe us.'}
          url="/clients"
        />
      </div>
      {/* <div className="bg-no-repeat bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-blogslide">
        <MainInfo
          headText={'Blogs & Articles'}
          NormalText={'Educating people over the knowledge they deserve.'}
        />
      </div> */}
      <div className="bg-no-repeat bg-cover w-[100%] h-[380px] md:h-[480px] text-left bg-teamslide">
        <MainInfo
          headText={'Team'}
          NormalText={'Experts just to assist you for the best.'}
          url="/team"
        />
      </div>
    </Carousel>
  );
}
