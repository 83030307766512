export const practiceData = [
  {
    id: 1,
    idval: 'litigation',
    title: 'LITIGATION',
    img: 'litigation-service.webp',
    description: 'Civil, Criminal, Labour Law, NCLT & MSME, Corporates, Family Law and Testamentary'
  },
  {
    id: 2,
    idval: 'nonlitigation',
    title: 'NON – LITIGATION',
    img: 'nonlitigation-service.jpg',
    description: 'Agreements & Contract and Compliance & Documentation, '
  },
  {
    id: 3,
    idval: 'ipr',
    title: 'IPR – INTELLECTUAL PROPERTY RIGHTS',
    img: 'litigation-service.webp',
    description:
      'The Intellectual Property group is committed to the creation and protection of several portfolios in various industries, such as information technology, life sciences, communications and retail. The Firm provides advice to leading national and multinational companies regarding the acquisition, enforcement and exploitation of their intellectual property rights. This Includes Complete compliance of Copyright, Trademark, Patent, etc., National as well as International Registration and Compliance including Registration, Infringement, Assignee, Passing off, Opposition (civil as well as Criminal Remedy).'
  },
  {
    id: 4,
    idval: 'posh',
    title: 'POSH – PREVENTION OF SEXUAL HARASSMENT AT WORKPLACE',
    img: 'litigation-service.webp',
    description:
      'Includes complete compliance of the PoSH norms in the company as per the rules and guidelines provided by the Hon’ble Supreme Court of India, inclusive of spreading awareness via Presentation & Seminars in the Company, establishment of the PoSH committee, reporting to the directed authority as per the Court Guidelines, and providing Redressal.'
  },
  {
    id: 5,
    idval: 'redevelopment',
    img: 'litigation-service.webp',
    title: 'REDEVELOPMENT',
    description:
      'Handling entire management and documentational work during the entire term of redevelopment project, including but not limited to drafting and vetting of Development agreement, Permanent Alternate Accommodation, Share Transfer etc.'
  },
  {
    id: 6,
    idval: 'alternate',
    title: 'Alternate dispute resolution',
    img: 'litigation-service.webp',
    description:
      'Our firm provides advice and counselling on dispute resolution and have successfully assisted clients in a number of domestic and international arbitration besides commercial disputes. We specialize in providing Client focused legal services ranging from advisory services to Domestic & International arbitrations. This includes Arbitration, Conciliation, Mediation and Negotiation, from appointment of the arbitrator/ mediator to the final disposal of the case. '
  }
];

export const practicesubinfo = {
  litigation: [
    {
      id: 1,
      title: 'Civil',
      description:
        'Our primary aim is to help clients, both domestic and transnational, avoid litigation wherever possible. The Firm adopts robust and carefully crafted litigation strategy for clients, to ensure, to the extent possible, positions of dominance for settlement negotiations and full- blown litigations/dispute resolution proceedings, should the need arise. The Firm&apos;s Litigation practice is focused on Corporate Commercial Disputes including Shareholder Joint Venture Disputes, Contractual Disputes (Commercial contracts, Licensing contracts, Project contracts, Construction contracts, Real Estate contracts, Technology Transfer contracts, Asset and Business Transfer contracts and other Commercial contracts and arrangements), and Constitutional matters affecting clients&apos; business interests.'
    },
    {
      id: 2,
      title: 'Criminal',
      description:
        'Including but not limited to matters related to white collar crimes, cheating, criminal breach of trust, defamation, sexual harassment (in relation with PoSH), EOW matters (Economic offence), Taxation matters, Cheque Bounce Cases (Negotiable Instrument), etc.'
    },
    {
      id: 3,
      title: 'Labour Law',
      description:
        'Includes compliance and management of ESIC, PF of the companies as well as undertaking matters pertaining to Industrial disputes, ESI Court matters, labour dispute, etc.'
    },
    {
      id: 4,
      title: 'NCLT & MSME',
      description:
        'Matters related to National Company Law tribunal – Insolvency & Bankruptcy, Mismanagement of Funds, Revival & Declaration, Merger and Acquisition, Conversion and such related Corporate Cases. Matters under Micro, Small, Medium Enterprises - Notice, filing of the matter and handling the proceeding inclusive of Arbitration/ Mediation'
    },
    {
      id: 5,
      title: 'Corporates',
      description:
        'Real Estate and Projects:- Our Firm provides sound, competent and comprehensive advice on all matters related to the real estate sector, in a cost- effective manner. The practice covers consulting on property development, sales, leases and acquisitions in Special Economic Zone, commercial, retail, industrial and residential sectors. The Real Estate group is proficient at offering strategic and transactional support while contending with challenges posed by the stringent real estate regulatory framework in India. Leading users of space, including technology service providers, financial institutions,investment banks, lenders, and major real estate developers rely on the Firm&apos;s expert advice for complex real estate transactions in emerging and established markets. Commercial and Corporate Advisory: We Advises on myriad commercial transactions such as licensing, franchising, joint venture, agency arrangements, leasing, service agreements, royalty and technical know-how, collaboration agreements etc. The Commercial Law and Corporate Advisory group represent an exemplary team of lawyers who provide tailored,practical advice to clients based on the legal and regulatory requirements of their business, we are regularly advising its clients in relation to general corporate, company law and Securities law issues that arise in the normal course of business. Business transactions in a wide range of key industry sectors, such as,finance, energy, infrastructure, manufacturing, technology, telecommunications and media, are effectively handled by the Firm&apos;s able lawyers.'
    },
    {
      id: 6,
      title: 'Family Law',
      description:
        'Including but not limited to Property related disputes within the family, Gift Deed, transfer of property, Divorce.'
    },
    {
      id: 7,
      title: 'Testamentary',
      description:
        'Includes Matters pertaining to drafting of will, disbursement of property pursuant to death of a member (without or without will), Release Deed, etc.'
    }
  ],
  nonlitigation: [
    {
      id: 1,
      title: 'Agreements & Contract',
      description:
        'Agreement related to Import & export; Property; Conveyance Agreement; Conducting Agreement; Shareholder Agreement; Partnership; Employment; Franchise; Endorsement; Business; Agreement regarding Construction and development, etc. This further includes drafting of the complete agreement/ master agreement and also contract vetting of National as well as International Documents.'
    },
    {
      id: 2,
      title: 'Compliance & Documentation',
      description:
        'Inclusive of all kinds of documentational drafting and compliance as per the requirements Such as POSH, Labour law, Legal Metrology, RERA, MAHDA, etc.'
    }
  ],
  ipr: [
    {
      id: 1,
      title: 'INTELLECTUAL PROPERTY RIGHTS',
      description:
        'The Intellectual Property group is committed to the creation and protection of several portfolios in various industries, such as information technology, life sciences, communications and retail. The Firm provides advice to leading national and multinational companies regarding the acquisition, enforcement and exploitation of their intellectual property rights. This Includes Complete compliance of Copyright, Trademark, Patent, etc., National as well as International Registration and Compliance including Registration, Infringement, Assignee, Passing off, Opposition (civil as well as Criminal Remedy).'
    }
  ],
  posh: [
    {
      id: 1,
      title: 'PREVENTION OF SEXUAL HARASSMENT AT WORKPLACE',
      description:
        'Includes complete compliance of the PoSH norms in the company as per the rules and guidelines provided by the Hon’ble Supreme Court of India, inclusive of spreading awareness via Presentation & Seminars in the Company, establishment of the PoSH committee, reporting to the directed authority as per the Court Guidelines, and providing Redressal. '
    }
  ],
  redevelopment: [
    {
      id: 1,
      title: 'REDEVELOPMENT',
      description:
        'Handling entire management and documentational work during the entire term of redevelopment project, including but not limited to drafting and vetting of Development agreement, Permanent Alternate Accommodation, Share Transfer etc.'
    }
  ],
  alternate: [
    {
      id: 1,
      title: 'ARBITRATION & CONCILIATION, MEDIATION',
      description:
        'Our firm provides advice and counselling on dispute resolution and have successfully assisted clients in a number of domestic and international arbitration besides commercial disputes. We specialize in providing Client focused legal services ranging from advisory services to Domestic & International arbitrations. This includes Arbitration, Conciliation, Mediation and Negotiation, from appointment of the arbitrator/ mediator to the final disposal of the case. '
    }
  ]
};
