import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {
  const [navbarOpen, setNavbarOpen] = useState(true);
  const routeName = useLocation();
  const urlname = routeName.pathname.replace('/', '');
  return (
    <div className="md:flex md:justify-between px-4 md:px-16 md:w-full bg-darkbg py-1 md:fixed w-full z-10">
      <div className="flex flex-row justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={require('../../assets/images/LOGO.png')} className="h-10 m-auto" />
          <div>
            <p className="font-RaleBold text-2xl pt-2 pb-0 text-white">Bhansali & Palekar</p>
            <p className="font-RaleBold text-center text-[10px] text-white">
              TRUSTED LEGAL SOLUTIONS
            </p>
          </div>
        </Link>
        {navbarOpen ? (
          <img
            src={require('../../assets/images/menu.png')}
            className="h-8 md:hidden"
            onClick={() => setNavbarOpen(false)}
          />
        ) : (
          <img
            src={require('../../assets/images/full-menu.png')}
            className="h-8 md:hidden"
            onClick={() => setNavbarOpen(true)}
          />
        )}
      </div>
      <ul className="hidden md:flex justify-between items-center">
        <li className="">
          <Link to="/">
            <p
              className={
                `${
                  urlname == ''
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'px-10 py-2 text-white'
              }>
              Home
            </p>
          </Link>
        </li>
        <li className="">
          <Link to="/services">
            <p
              className={
                `${
                  urlname == 'services'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'px-10 py-2 text-white'
              }>
              Services
            </p>
          </Link>
        </li>
        <li className="">
          <Link to="/clients">
            <p
              className={
                `${
                  urlname == 'clients'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'px-10 py-2 text-white'
              }>
              Clients
            </p>
          </Link>
        </li>
        {/* <li className="">
          <Link to="/blogs">
            <p
              className={
                `${
                  urlname == 'blogs'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'px-10 py-2 text-white'
              }>
              Blogs & Articles
            </p>
          </Link>
        </li> */}
        <li className="">
          <Link to="/team">
            <p
              className={
                `${
                  urlname == 'team'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'px-10 py-2 text-white'
              }>
              Team
            </p>
          </Link>
        </li>
      </ul>

      <ul className={`${navbarOpen ? 'hidden' : 'block'}` + ' md:hidden'}>
        <li className="">
          <Link to="/">
            <p
              className={
                `${
                  urlname == ''
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
              }>
              Home
            </p>
          </Link>
        </li>
        <li className="">
          <Link to="/services">
            <p
              className={
                `${
                  urlname == 'services'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
              }>
              Services
            </p>
          </Link>
        </li>
        <li className="">
          <Link to="/clients">
            <p
              className={
                `${
                  urlname == 'clients'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
              }>
              Clients
            </p>
          </Link>
        </li>
        {/* <li className="">
          <Link to="/blogs">
            <p
              className={
                `${
                  urlname == 'blogs'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'border-b-[1px] md:px-10 pt-2 pb-1 md:py-2 text-white'
              }>
              Blogs & Articles
            </p>
          </Link>
        </li> */}
        <li className="">
          <Link to="/team">
            <p
              className={
                `${
                  urlname == 'team'
                    ? 'font-RaleSemiBold text-[18px]'
                    : 'font-RaleRegular text-[15px] opacity-85'
                } ` + 'md:px-10 pt-2 pb-1 md:py-2 text-white'
              }>
              Team
            </p>
          </Link>
        </li>
      </ul>
    </div>
  );
}
