import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

export default function Client() {
  return (
    <>
      <Header />
      <div className="bg-whiteDefault">
        <p className="bg-gradient-to-r from-dark to-gray-400 w-full p-16 text-center font-RaleRegular text-light text-3xl md:py-36 md:pb-20">
          Clients
        </p>
        <div className="p-4 md:p-16 bg-darkbgDefault">
          <p className="font-RaleSemiBold text-dark text-xl">
            Our Well Known Clients provided with justice:
          </p>
          <div className="mt-5 grid md:grid-cols-5 gap-4">
            <p className="font-RaleBold text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] shadow-lg border-2 rounded-md">
              GS CALTEX INDIA PVT LTD
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-8 border-default hover:bg-white opacity-[50%] shadow-lg border-2 rounded-md">
              RINA INDIA PRIVATE LIMITED – REGISTRO ITALIANO NAVALE (ITALITAN NAVAL REGISTER)
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              REMSONS INDUSTRIES PVT LTD
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              PLADA INFOTECH PVT LTD
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              DOTSMARK SYSTEMS INDIA LIMITED
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              SIMSON PHARMA LIMITED
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              EMSONS EXIM PVT LTD
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              INFEXION PVT LTD
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-8 border-default hover:bg-white opacity-[50%] shadow-lg border-2 rounded-md">
              UCIL SYNCHEM PVT LTD
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              OM PETRO CHEMICALS
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              PARVATI DEVELOPERS
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              SHREE RAJ
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              KHUSHI MULTIPACK LLP
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              ENGAGE OUTDOORS MEDIA
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              INDUSTRIAL ENGINEERING SYNDICATE
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              DELUX BEARING PVT LTD
            </p>
            <p className="font-RaleBold  text-dark hover:text-black text-center text-sm p-10 border-default hover:bg-white opacity-[50%] border-2 rounded-md shadow-lg">
              DLXINFINITY DRIVELINE PVT LTD.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
