import React from 'react';
import Footer from '../Footer';
import Header from '../Header';

export default function Terms() {
  return (
    <>
      <Header />
      <div>
        <div className="px-4 md:p-16 pb-4 md:pb-0 bg-default pt-8 md:pt-20">
          <div className="md:pr-10 md:pb-20">
            <div className="cursor-pointer">
              <div className="md:px-6">
                <p className="text-dark font-RaleBold capitalize text-xl md:text-3xl pt-10">
                  Terms and Conditions
                </p>
                <p className="text-justify font-RaleRegular mt-2 text-lg overflow-hidden">
                  1. This website is operated by Bhansali & Palekar - Trusted Legal Solution
                  <br />
                  2. The Bar council of India does not permit advertisement or solicitation by
                  advocates in any form or manner.
                  <br />
                  3. By accessing this website (www.BhansaliandPalekar.com), including the whole or
                  any part of the web pages located at the website, layout of this website;
                  individual elements of this website&apos;s design; underlying code elements of the
                  website; or text, sounds, graphics, animated elements or any other content of this
                  website and associated mobile applications (collectively, “Website”), you are
                  deemed to accept the following terms and conditions and acknowledge and confirm
                  that you are seeking information relating to Bhansali & Palekar of your own accord
                  and that there has been no form of solicitation, advertisement or inducement by
                  Bhansali & Palekar or its members.
                  <br />
                  4. The content of this website is for informational purposes only and should not
                  be interpreted as soliciting or advertisement. No material/information provided on
                  this website should be construed as legal advice. Bhansali & Palekar shall not be
                  liable for consequences of any action taken by relying on the material/information
                  provided on this website.
                  <br />
                  5. As you browse through this website you may access other websites that are
                  subject to different terms of use. When using these other sites, you will be bound
                  by the terms and conditions posted on those websites. <br />
                  6. Bhansali & Palekar may change these terms of use at any time without notice.
                  Any amendment will be effective immediately. You are encouraged to periodically
                  visit this page to review the Terms and Conditions and any changes thereto.
                  Discontinuing use of the Website will not affect the applicability of the Terms
                  and Conditions to your prior uses of the Website. <br />
                  7. Bhansali & Palekar makes no representations about the suitability, reliability,
                  timeliness, comprehensiveness and accuracy of the information, services and other
                  content contained on this website. The content on this website is provided by
                  Bhansali & Palekar in good faith on an {`"`}as is{`"`} basis for general
                  information purposes only and is not intended to constitute or substitute legal or
                  other professional advice. It should be noted that such information can rapidly
                  become out of date. You must make your own assessment of the information and rely
                  on it wholly at your own risk. Bhansali & Palekar may, from time to time, change
                  or add to this website without notice. However, we do not undertake to keep this
                  website updated. Bhansali & Palekar is not liable to you or anyone else if errors
                  occur in the information on this website or if that information is not up-to-date.
                  <br /> 8. Legal content on this website relates only to the law or laws it is
                  specified to apply to, and that law may be different from your law. <br />
                  9. Bhansali & Palekar shall not be liable directly or indirectly in contract,
                  tort, equity or otherwise for any damage whatsoever in connection with this
                  website, use of content provided on this website or any other website hyperlinked
                  from the Website, including any direct, indirect, special, incidental or
                  consequential damage (including but not limited to loss of profits, interest,
                  data, business revenue, anticipated savings, business or goodwill). <br />
                  10. This general disclaimer is not restricted or modified by any specific warnings
                  and disclaimers elsewhere on this website. <br />
                  11. This website is copyright property of Bhansali & Palekar, and all rights are
                  reserved. You are provided with access to it only for your personal and
                  non-commercial use. You may not, in any form or by any means: (i) adapt,
                  reproduce, store, distribute, transmit, print, display, perform, publish or create
                  derivative works from any part of this website; or (ii) commercialise any
                  information, products or services obtained from any part of this website, without
                  our written permission. <br />
                  12. You may not remove, change or obscure the Marks or any notices of proprietary
                  rights on any content of this website. <br />
                  13. All trade names, trademarks, service marks and other product and service names
                  and logos (the {`"`}Marks{`"`}) displayed on the website are proprietary to their
                  respective owners and are protected by applicable trade mark and copyright laws.
                  These Marks may be registered or unregistered marks of Bhansali & Palekar or
                  others. <br />
                  14. All rights in this website and the content on this website including
                  copyright, design rights, patents, inventions, knowhow, database rights, trade
                  marks, source codes and any other intellectual property rights in any of the
                  foregoing are reserved to Bhansali & Palekar and/or their content and technology
                  providers.
                  <br /> 15. Nothing contained on the website should be construed as granting any
                  licence or right of use of any other person&apos;s or entity&apos;s trade mark
                  which is displayed on this website without their express permission. <br />
                  16. You may not link the homepage or any other parts of this website without prior
                  written consent from Bhansali & Palekar.
                  <br /> 17. This website may contain links to other websites solely for your
                  convenience only and may not remain current or be maintained. Bhansali & Palekar
                  does not endorse, recommend or approve of any information, products or services
                  referred to on such linked sites and assumes no responsibility for the contents of
                  any other website to which this website offers links. <br />
                  18. Unless stated otherwise on this website, Bhansali & Palekar has: (i) no
                  relationship with the owners or operators of those linked website; and (ii) no
                  control over or rights in those linked website. <br />
                  19. These terms of use incorporate, and should be read together with, the Bhansali
                  & Palekar Privacy Policy. <br />
                  20. If you express interest in products or services through your use of this
                  website, you consent to Bhansali & Palekar sending commercial electronic messages
                  (including messages about Bhansali & Palekar products and services and the
                  products and services of third parties) to electronic addresses which you have
                  provided to Bhansali & Palekar or for which you or your employer (or your
                  employer&apos;s related bodies corporate) are the relevant electronic account
                  holder. <br />
                  21. These terms and conditions and any dispute, controversy, proceedings or claim
                  of whatever nature arising out of or in any way relating to these terms and
                  conditions shall be governed by and construed in accordance with Indian law and
                  the courts of Mumbai shall have exclusive jurisdiction to resolve any disputes
                  between us relating to these terms and conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
