import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="w-[100%] bg-darkbg">
      <div className="p-3 md:p-16 md:pt-3 md:pb-3 text-center">
        <img src={require('../../assets/images/LOGO.png')} className="h-8 m-auto" />
        <p className="font-RaleSemiBold text-xl text-light">Bhansali & Palekar</p>
        <p className="font-RaleBold text-[12px] md:text-xs mb-4 text-white">
          TRUSTED LEGAL SOLUTIONS
        </p>
        <div className="flex justify-center space-x-4">
          <img src={require('../../assets/images/linkedin.png')} className="h-8" />
          <img src={require('../../assets/images/gmail.png')} className="h-8" />
          <a
            href="https://api.whatsapp.com/send?phone=+919819116321&text=Hello"
            target="_blank"
            rel="noreferrer">
            <img src={require('../../assets/images/whatsappicon.png')} className="h-8" />
          </a>
        </div>
        <div className="md:flex flex-col items-center">
          <div className="flex md:flex-row space-x-3 pt-2 text-center md:text-center justify-center">
            <Link to="/terms">
              <p className="text-md font-semibold mt-2 text-light">Terms</p>
            </Link>
            <Link to="/privacy-policy">
              <p className="text-md font-semibold mt-2 text-light">Privacy Policy</p>
            </Link>
            <p className="text-md font-semibold mt-2 text-light">Contact Us</p>
          </div>
          <div className="pt-5">
            <div className="flex justify-between md:space-x-5">
              <div className="flex flex-col items-center">
                <img src={require('../../assets/images/ketanconsultancy.png')} className="h-8" />
                <p className="text-md font-semibold  text-light">Ketan Consultants</p>
              </div>
              <div className="flex flex-col items-center">
                <img src={require('../../assets/images/kbc.png')} className="h-8" />
                <p className="text-md font-semibold  text-light">Karan Bhasali Legal Consultancy</p>
              </div>
              <div className="flex flex-col items-center">
                <img src={require('../../assets/images/ketanconsultancy.png')} className="h-8" />
                <p className="text-md font-semibold  text-light">Ketan Consultants</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
