import React from 'react';
import Footer from '../Footer';
import Header from '../Header';

export default function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div>
        <div className="px-4 md:p-16 pb-4 md:pb-0 bg-default pt-8 md:pt-20">
          <div className="md:pr-10 md:pb-20">
            <div className="cursor-pointer">
              <div className="md:px-6">
                <p className="text-dark font-RaleBold capitalize text-xl md:text-3xl pt-10">
                  Privacy Policy
                </p>
                <p className="text-justify font-RaleRegular mt-2 text-lg overflow-hidden">
                  1. Introduction. <br />
                  Welcome to our website. This privacy policy is developed and published pursuant to
                  inter-alia, the Information Technology Act, 2000 ({`"`}IT Act{`"`}) and the rules
                  made thereunder. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;In this policy, we will deal with:
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. Type of personal
                  information we collect; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. Purposes for which we collect
                  and use such information; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. How we process, disclose and
                  protect such information <br />
                  2. General <br />
                  This Policy applies to collection of information from:
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. Persons accessing our
                  website; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. Clients and potential clients;
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. Applicants interested in
                  working with us, including interns; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. Our existing talent and
                  employees. <br />
                  This policy does not govern collection and processing of information by us in the
                  course of provision of our services, or otherwise by virtue of entering into any
                  engagement or other contractual obligations. In such cases, the terms and
                  conditions governing the provisions of our services, engagement agreement or other
                  contractual terms will be applicable. <br />
                  3. Collection <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. In terms of the Information
                  Technology (Reasonable Security Practices and Procedures and Sensitive Personal
                  Data or Information) Rules, 2011 ({`"`}IT Rules
                  {`"`}), ‘sensitive personal data or information’ of a person means such personal
                  information which consists of information relating to: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. Password <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. Financial information such as
                  Bank account or credit card or debit card or other payment instrument details;{' '}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. Physical, physiological and
                  mental health conditions; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. Sexual orientation <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. Medical records and history;
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. Biometric information <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. Any detail relating to the
                  above classes as provided to body corporate for providing service; and <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. Any of the information
                  received under any of the classes (a) to (g) above, by a body corporate for
                  processing, stores or processed under lawful contract or otherwise. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Further, any information that is
                  freely available or accessible in public domain or furnished under the Right to
                  Information Act, 2005 or any other law for the time being in force shall not be
                  regarded as sensitive personal data or information for the purposes of the IT
                  Rules. <br />
                  In terms of other applicable data protection laws, personal data means any
                  information relating to an identified or identifiable natural person. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. We may collect information from
                  you of a nature, which may fall within any of the classes above. Common examples
                  of such personal information, include but are not limited to: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. your full name, parents’ names,
                  temporary and permanent address, telephone numbers and email address; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. age, date of birth and in case
                  you work with us, biometrics such as finger prints; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. previous employment details
                  including previous job title, names of previous employers, your previous
                  compensation and financial statement; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. information and documents
                  including prior correspondence with our firm; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. financial information in
                  relation to payments due and / or received; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. details about when and how
                  frequently you have accessed our website you IP address and location. <br />
                  c. Acceptance <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. While we may collect some
                  information under paragraphs 3(b)(i),(ii),(iii) and 3(b)(vi) above when you access
                  our website, we may also collect other personal information from you, with your
                  prior consent. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. You do hereby, accept and
                  consent to the collection, use, storage, processing and sharing or disclosure of
                  your information as described in this privacy policy and in accordance with the
                  applicable data protection laws. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. In the event any information
                  collected from you contains information relating to third parties, you agree that
                  you have collected such information in compliance with applicable law and are duly
                  authorized to furnish it to us. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. Our firm reserves the right
                  to, at its discretion, modify this policy at any time. This policy, as modified
                  from time to time will be available on our website. Please ensure that you visit
                  our website to keep abreast with all such modifications. By further accessing our
                  website or providing us with your information you will be deemed to have accepted
                  all such modifications. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. In the event of a change in the
                  applicable law, your consent shall be deemed to valid to the extent permissible
                  under applicable law. To the extent required by any such change, we may seek
                  additional consents and authorizations from you. <br />
                  4. Purposes of Collection and Use of Information
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. The information you may
                  share with us will be used, stored and processed in compliance with the IT Act, IT
                  Rules and other applicable laws relating to data protection, for the above
                  purposes of or in connection with: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. operating our website and
                  improving its functioning, technical and other parameters to cater to a better
                  user experience and to align it to the beliefs of our firm; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. providing legal updates to
                  you; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. complying with applicable
                  law; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. processing applications
                  received from you and responding to the same;
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. processing queries
                  raised and received and responding to the same; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. internal purposes such as
                  creating and maintaining records; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. providing information to
                  auditors, accountants or otherwise pursuant to investigations or inquiries; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. inviting you for seminars,
                  webinars and workshops conducted by us, which we believe may be of interest to
                  you; and <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ix. contacting you from time to
                  time to record your valuable feedback on our website, as they currently stand,
                  and/or any potential services that may be offered in the future. <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. In addition, we may share your
                  information to third parties on a need-to-know basis only, in connection with or
                  pursuant to provision by us of our services or your employment with us. Details
                  regarding disclosures to third parties is dealt with in paragraph 5 below. <br />
                  5. Disclosure of Information
                  <br /> We may disclose your information to third parties in the following cases,
                  in accordance with the applicable data protection laws: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. disclosure to third party
                  service providers such as auditors, accountants, information technology service
                  providers, our website developers and designers, insurers, bankers, researchers,
                  business consultants and other professional advisors or service providers for
                  providing inter-alia, data processing; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. where there is a legal
                  obligation to disclose such information to third parties, either under applicable
                  law or order or judgment of a court of competent jurisdiction or pursuant to any
                  other legal process;
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. to protect the rights,
                  interests and security of our firm, our property, our clients and those who work
                  with us. <br />
                  Third Party Links: <br />
                  Our website also contains links to third parties’ websites in respect of which we
                  do not have any rights. You hereby agree that we are not responsible for the
                  collection, use, processing and disclosure of any information by virtue of your
                  accessing such links and websites. <br />
                  Social Media Handles: <br />
                  In case you provide us any personal information through inbox messages or posts
                  (as applicable) on our social media pages (eg. on LinkedIn), to the extent that
                  such messages and posts containing the personal information, are sent to our
                  social media accounts, the personal information will be subject to this privacy
                  policy and the applicable data protection laws. However, you hereby agree that
                  given that our social media handles are on third party websites, we are not
                  responsible for collection, use, processing and disclosure of any of your
                  information by third parties (including the social media websites themselves,
                  members of the public, etc.) by virtue of your accessing or providing information
                  on such social media websites (whether or not to our social media page) or
                  otherwise engaging in any other activities on such websites. <br />
                  6. Data Protection
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We value the privacy of the
                  personal information collected from you and endeavor to safeguard the same from
                  unauthorized use, disclosure, alteration and destruction. In order to do so, we
                  maintain appropriate managerial, technical, operational and physical security
                  control measures in relation to our computer systems. We also ensure that if any
                  disclosures are made or any access is provided to your personal information, it is
                  on a need-to-know basis only to persons who maintain at least the same level of
                  safeguards for data protection and who are subject to confidentiality obligations.
                  In our endeavor to protect your personal information, where we find that your
                  personal information is no longer required for any of the purposes (under
                  paragraph 4 above) for which we collected it or otherwise it is not required to be
                  retained pursuant to any applicable data protection law, we will not store it.
                  However, you agree that we may store your personal information in electronic back
                  up form, in which case such backups would be subject to appropriate safeguards
                  (including technical and organizational). <br />
                  7. Disclaimer <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notwithstanding the above, you
                  agree that we shall not be responsible for: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. any unauthorized use,
                  collection, processing or access to your personal information or breaches of
                  security beyond our firm’s reasonable control; and <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. any liability arising in
                  connection with disclosure by you of your personal information to third parties,
                  whether by you accessing third party webpages or websites through our website, or
                  in any other manner. <br />
                  8. Your Rights of Access to Your Information with Us
                  <br /> You may exercise rights in relation to your personal information by
                  requesting for: <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a. the personal information
                  collected from you or in relation to you; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. any modification or updation of
                  any of your personal information; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c. withdrawal of your consent;{' '}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d. restriction on use or
                  processing of your personal information in any manner or to any extent; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e. erasure of your personal
                  information; <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f. transmission of your personal
                  data by us to another controller as specified by you, subject to the applicable
                  data protection laws; by writing to us at the contact details mentioned at
                  paragraph 9 below. In addition to the above, you are also entitled to the right to
                  lodge a complaint with a supervisory authority. We do not have any automated
                  decision making or profiling in relation to your personal information. <br />
                  9. Contact Information <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case you have any questions
                  pursuant to this privacy policy or if you have any complaints or pursuant to
                  paragraph 8 above, if you have any requests, please write to us at:
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;kbhansaliconsultancy@gmail.com{' '}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09- Gr. Floor, Suryakiran Apt.
                  Avdhoot Nagar, Dahisar (E) Mumbai 400 068, MH, INDIA.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
