import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import FlipInfo from './FlipInfo';
import Slider from './Slider';
import { useState } from 'react';
import Modal from 'react-modal';

function Home() {
  const [showdisclaim, setShowDisclaim] = useState(false);

  return (
    <>
      <Header />
      <div>
        <Slider />
        <FlipInfo />
      </div>
      <Footer />
      <Modal isOpen={showdisclaim}>
        <div className="bg-darkbgDefault w-full md:h-full md:p-10 pt-5">
          <p className="text-dark text-4xl capitalize text-center font-RaleSemiBold border-b-2 border-dark pb-2 md:pb-5 mx-8 md:mx-36">
            Disclaimer
          </p>
          <div className="flex flex-col items-center mt-2 md:mt-5">
            <p className="text-black text-justify font-RaleRegular z-50 text-sm mx-6 md:mx-16 mt-6 ">
              As per the rules of the Bar Council of India, we are not permitted to solicit work or
              advertise in any manner. By proceeding further and clicking on “I Agree” below, the
              user acknowledges that the transmission, receipt or use of the information on our
              website does not tantamount to solicitation, advertisement, inducement or personal
              communication of any sort for and on behalf of the Firm so as to create an
              attorney-client relationship.
              <br />
              <br />
              The information provided herein should not be interpreted as legal advice, for which
              the user must make independent inquiries. Whilst every effort has been taken to ensure
              the accuracy of the contents of this website, Bhansali & Palekar disclaims all
              liability arising from reliance placed by the user or any other third party on the
              information contained therein.
            </p>

            <button
              className="bg-dark m-auto text-center px-4 py-2 mt-8 md:mt-16 text-white"
              onClick={() => setShowDisclaim(false)}>
              I Accept
            </button>
            <a
              className=" m-auto text-center px-4 py-2 mt-2 mb-4 md:mb-[unset] text-dark underline"
              href="javascript:history.back()">
              Dismiss
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Home;
